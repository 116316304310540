import { inject }                  from 'aurelia-framework';
import { ConstructionsRepository } from 'modules/management/concessions/constructions/services/repository';
import { LotConstruction }         from 'modules/management/models/lot-construction';

@inject(ConstructionsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param constructionsRepository
     */
    constructor(constructionsRepository) {
        this.constructionsRepository = constructionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotConstruction}
     */
    model() {
        let model = new LotConstruction();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.element_id = {
            type:         'select2',
            key:          'element_id',
            label:        viewModel.fieldTitle,
            size:         6,
            remoteSource: () => this.constructionsRepository.allByType(viewModel.construction_type_id),
            attributes:   {
                disabled: readonly,
            },
        };

        this.designation = {
            type:       'text',
            key:        'designation',
            label:      'form.field.designation',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.number = {
            type:       'text',
            key:        'number',
            label:      'form.field.number',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       6,
            required:   false,
            attributes: {
                maxlength: 10,
                readonly:  readonly,
            },
        };

        this.pk = {
            type:     'field-group',
            label:    'form.field.pk-location',
            key:      'pk',
            required: false,
            size:     6,
            fields:   [
                {
                    type:            'text',
                    key:             'pk_s',
                    showLabel:       false,
                    required:        false,
                    size:            '45p',
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                        readonly:  readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '10p',
                },
                {
                    type:            'text',
                    key:             'pk_e',
                    showLabel:       false,
                    required:        false,
                    size:            '45p',
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                        readonly:  readonly,
                    },
                },
            ],
        }

        const schema = [
            [this.element_id, this.designation],
            [this.number, this.code],
            [this.pk],
        ];

        if (readonly) {
            this.concession = {
                type:       'text',
                key:        'concession',
                label:      'form.field.concession',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.highway = {
                type:       'text',
                key:        'highway',
                label:      'form.field.highway',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [
                ...schema,
                [this.concession, this.highway],
                [this.created_by, this.created_at],
            ];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.concessions.concessions.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
