import { inject }                     from 'aurelia-framework';
import { PLATFORM }                   from 'aurelia-pal';
import { BaseFormViewModel }          from 'base-form-view-model';
import { FormSchema }                 from 'modules/management/concessions/concessions-tree/lot-constructions/form-schema';
import { LotConstructionsRepository } from 'modules/management/concessions/concessions-tree/lot-constructions/services/repository';
import { AppContainer }               from 'resources/services/app-container';

@inject(AppContainer, LotConstructionsRepository, FormSchema)
export class CreateLotConstruction extends BaseFormViewModel {

    headerTitle     = 'form.title.create-new-record';
    elementTabLabel = 'form.title.element';
    type;
    construction_type_id;
    lot_construction_type_id;
    lot_construction_type_slug;
    parent_id;
    fieldTitle;
    formId;

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        return super.can([
            'management.concessions.concessions.lot-constructions.manage',
            'management.concessions.concessions.lot-constructions.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.parent_id                  = params.filterModel.parent_id;
        this.construction_type_id       = params.type.id;
        this.lot_construction_type_slug = params.type.slug;
        this.fieldTitle                 = params.type.title;
        this.lot_construction_type_id   = params.type.lot_construction_type_id;

        this.formId = `management.concessions.lot-constructions-${this.lot_construction_type_slug}.create-form`;

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model, this.parent_id, this.lot_construction_type_id)
            .then((response) => {
                this.handleResponse(response, 'management.concessions.concessions.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
